<template>
  <div v-loading="loading" class="custom-dashboard-scheduler">
    <div class="d-flex p-2" style="border-bottom: 1px solid #ddd">
      <div class="left-card" style="min-width: 10vw">
        <div>
          <i class="fa fa-calendar today-icon"></i>
          <b>{{ component.component_name }}</b>
        </div>
      </div>
      <div class="d-flex right-card top-content">
        <div>
          <el-button
            type="primary"
            size="mini"
            @click="saveChanges"
            :loading="saveLoading"
            >Save</el-button
          >
          <el-button
            type="primary"
            size="mini"
            @click="copyFromLastWeek"
            :loading="copyLoading"
            >Copy from last week</el-button
          >
          <el-dropdown trigger="click" v-if="!hide_options">
            <el-button
              icon="el-icon-more"
              style="transform: rotate(90deg); height: 40px"
              type="text"
            ></el-button>
            <el-dropdown-menu slot="dropdown">
              <a @click="openDialog">
                <el-dropdown-item>Settings</el-dropdown-item>
              </a>
              <a @click="deleteComponent">
                <el-dropdown-item>Delete</el-dropdown-item>
              </a>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="d-flex m-2">
      <div class="left-card" style="min-width: 10vw">
        <div>
          <el-date-picker
            v-model="currentWeek"
            type="week"
            format="yyyy MMM"
            placeholder="Pick a week"
            @change="prepareScheduleEntityData"
          >
          </el-date-picker>
        </div>
      </div>
      <div class="d-flex right-card top-content">
        <div>
          <el-button-group>
            <el-button size="mini" type="primary" @click="changeWeek(true)"
              >Current week</el-button
            >
            <el-button
              size="mini"
              type="primary"
              icon="el-icon-arrow-left"
              @click="changeWeek(false, false)"
              >Last week</el-button
            >
            <el-button
              size="mini"
              type="primary"
              @click="changeWeek(false, true)"
              >Next week <i class="el-icon-arrow-right"></i
            ></el-button>
          </el-button-group>
        </div>
      </div>
    </div>
    <div>
      <div class="calendar">
        <div class="weekView">
          <el-scrollbar>
            <table>
              <thead>
                <tr>
                  <th>
                    <el-select
                      size="mini"
                      placeholder="Please select"
                      v-model="viewBy"
                    >
                      <el-option
                        v-for="view in allViews"
                        :key="view.key"
                        :label="view.name"
                        :value="view.key"
                      ></el-option>
                    </el-select>
                  </th>
                  <th
                    class="p-1"
                    v-for="day in schedulerWeekDays"
                    :key="`${day.weekday} ${day.month}/${day.day}`"
                  >
                    <span class="table-header">{{
                      `${day.weekday} ${day.month}/${day.day}`
                    }}</span>
                    <div style="display: flex; gap: 5px">
                      <div>
                        <i class="el-icon-user-solid"></i>
                        <span>{{ getCountValue(day, "RESOURCE") }}</span>
                      </div>

                      <div>
                        <i class="el-icon-time"></i>
                        <span>{{ formatTime(getCountValue(day, "HOURS")) }}</span>
                      </div>

                      <div>
                        <i class="el-icon-bank-card"></i>
                        <span>{{ getCountValue(day, "JOB") }}</span>
                      </div>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="selectedView(viewBy)?.filters">
                  <template v-for="filter in selectedView(viewBy).filters">
                    <h3
                      :key="'resource_' + filter"
                      class="ml-1"
                      style="margin-top: 5px"
                    >
                      {{
                        allFiltersData?.[filter.split("@")[1]]?.filter_name ||
                        ""
                      }}
                    </h3>
                    <tr
                      v-for="(row, i) in allDividedEntitiesData[
                        filter.split('@')[1]
                      ]"
                      :key="'resource_' + row._id"
                    >
                      <td>
                        {{
                          getLabel(
                            allEntities[selectedView(viewBy)?.entity_id || ""],
                            row,
                            i
                          )
                        }}
                      </td>
                      <td
                        class="table-cell"
                        v-for="day in scheduleEntityData"
                        :key="`${day.weekday}/${day.month}/${day.day}_${row._id}_${row.label}`"
                        :id="`${day.month}_${day.day}_${row._id}_${row.label}`"
                      >
                        <template>
                          <draggable
                            :list="day.tasks"
                            :group="'tasks'"
                            @end="
                              onEnd(
                                $event,
                                `${day.month}_${day.day}_${row._id}_${row.label}`
                              )
                            "
                            class="dragArea"
                            tag="div"
                            :disabled="checkPreviousTasks(day)"
                          >
                            <div
                              v-for="item in (day?.tasks || []).filter(
                                (e) => e[viewBy] == row._id
                              )"
                              :key="item._id"
                              class="job-card"
                              :id="item._id"
                              :style="`padding: 3px 0px 3px; background-color: ${
                                jobColors[item[jobView]] || 'lightblue'
                              } !important;`"
                            >
                              {{ item.label }}
                            </div>
                          </draggable>
                          <div class="hidden-button">
                            <el-link
                              icon="el-icon-plus"
                              :underline="false"
                              type="primary"
                              @click="
                                openDataModal(
                                  row,
                                  day.day,
                                  (day?.tasks || []).filter(
                                    (e) => e[viewBy] == row._id
                                  )
                                )
                              "
                            ></el-link>
                          </div>
                        </template>
                      </td>
                    </tr>
                  </template>
                </template>
                <template v-else>
                  <tr
                    v-for="(row, i) in allEntitiesData[
                      selectedView(viewBy)?.entity_id || ''
                    ]"
                    :key="'resource_' + row._id"
                  >
                    <td>
                      {{
                        getLabel(
                          allEntities[selectedView(viewBy)?.entity_id || ""],
                          row,
                          i
                        )
                      }}
                    </td>
                    <td
                      class="table-cell"
                      v-for="day in scheduleEntityData"
                      :key="`${day.weekday}/${day.month}/${day.day}_${row._id}_${row.label}`"
                      :id="`${day.month}_${day.day}_${row._id}_${row.label}`"
                    >
                      <template>
                        <draggable
                          :list="day.tasks"
                          :group="'tasks'"
                          @end="
                            onEnd(
                              $event,
                              `${day.month}_${day.day}_${row._id}_${row.label}`
                            )
                          "
                          class="dragArea"
                          tag="div"
                          :disabled="checkPreviousTasks(day)"
                        >
                          <div
                            v-for="item in (day?.tasks || []).filter(
                              (e) => e[viewBy] == row._id
                            )"
                            :key="item._id"
                            class="job-card"
                            :id="item._id"
                            :style="`padding: 3px 0px 3px; background-color: ${
                              jobColors[item[jobView]] || 'lightblue'
                            } !important;`"
                          >
                            {{ item.label }}
                          </div>
                        </draggable>
                        <div class="hidden-button">
                          <el-link
                            icon="el-icon-plus"
                            :underline="false"
                            type="primary"
                            @click="
                              openDataModal(
                                row,
                                day.day,
                                (day?.tasks || []).filter(
                                  (e) => e[viewBy] == row._id
                                )
                              )
                            "
                          ></el-link>
                        </div>
                      </template>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </el-scrollbar>
        </div>
      </div>
      <el-drawer
        :title="currentDate"
        :visible.sync="addingNewData"
        :with-header="true"
        size="50%"
        class="scheduler-drawer"
      >
        <div
          v-if="allEntities?.[this.component.scheduler_entity] && addingNewData"
        >
          <fields-preview-template
            :templateData="
              allEntities[this.component.scheduler_entity].templates[0]
                .templateInfo
            "
            :has-next="false"
            :workflow-step-id="
              allEntities[this.component.scheduler_entity].templates[0]._id
            "
            :template-data-id="
              allEntities[this.component.scheduler_entity].templates[0]
                .templateDataId
            "
            :currentStep="
              allEntities[this.component.scheduler_entity].templates[0]
            "
            :entityData="null"
            :is-execute="true"
            :layout="'STANDARD'"
            :currentEntity="allEntities?.[this.component.scheduler_entity]"
            :isEdit="true"
            :currentMenu="null"
            :entityDataExists="false"
            :suggestionEntityForm="schedulingTemplateForm"
            :entityFieldsPreferredData="entityFieldsPreferredData"
            :fromScheduling="true"
            v-on:entity-data-created="newDataCreated"
            v-on:entity-data-added="newDataCreated"
          >
          </fields-preview-template>
        </div>
      </el-drawer>
    </div>
    <dialog-component
      :title="dialogTitle"
      :visible="dialogVisible"
      @before-close="closeDialog"
      :containerMaxWidth="'50vw'"
    >
      <div style="padding: 20px" class="desription-text">
        <el-checkbox
          v-model="updateComponentSettings.allow_previous_slots_replace"
          >Allow previous slots to be updated</el-checkbox
        >
        <el-checkbox v-model="updateComponentSettings.allow_extra_time_job"
          >Allow users to work on extra slots</el-checkbox
        >
      </div>
      <span slot="footer">
        <button @click="closeDialog"><i class="fa fa-close"></i> Cancel</button>
        <button type="primary" @click="saveSettings">
          <i class="fa fa-check-square-o"></i> Save
        </button>
      </span>
    </dialog-component>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import moment from "moment";
import { postAPICall } from "@/helpers/httpHelper";
import CustomDashboardHelper from "@/mixins/CustomDashboardHelper.js";
import { fetchFilterById } from "@/repo/filtersRepo";

export default {
  data() {
    return {
      loading: false,
      loadingText: "Getting info..",
      shouldCenterMonth: false,
      currentDate: "",
      currentYear: new Date().getFullYear(),
      currentView: "Month",
      daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
      hours: [
        "12AM",
        "1AM",
        "2AM",
        "3AM",
        "4AM",
        "5AM",
        "6AM",
        "7AM",
        "8AM",
        "9AM",
        "10AM",
        "11AM",
        "12PM",
        "1PM",
        "2PM",
        "3PM",
        "4PM",
        "5PM",
        "6PM",
        "7PM",
        "8PM",
        "9PM",
        "10PM",
        "11PM",
      ],
      monthsNames: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      openNewEventModal: false,
      openNewEventLoading: false,
      selectedEntity: "",
      currentEntity: null,
      entityAllFields: [],
      form: {},
      primaryFields: [],
      selectedData: null,
      showChildEntityDataDialog: false,
      calenderDataByMonth: {},
      calenderDateRangeByMonth: {},
      currentWeek: new Date(),
      allEntitiesData: {},
      allEntities: {},
      viewBy: "",
      allViews: [],
      addingNewData: false,
      schedulingTemplateForm: {},
      resource_entity_field: "",
      job_entity_field: "",
      scheduler_entity_sub_entity_field: {},
      entityFieldsPreferredData: {},
      scheduleEntityData: [],
      scheduleEntityDataObject: {},
      refresh: false,
      jobColors: {},
      saveLoading: false,
      copyLoading: false,
      newlyAddedData: [],
      dialogVisible: false,
      dialogTitle: "",
      updateComponentSettings: {
        allow_previous_slots_replace: true,
        allow_extra_time_job: false,
      },
      allDividedEntitiesData: {},
      allFiltersData: {},
    };
  },
  components: {
    draggable,
    FieldsPreviewTemplate: () =>
      import("@/components/templates/templateFieldsPreview.vue"),
  },
  props: [
    "isFromApplicationuser",
    "selectedEntities",
    "customDashboard",
    "parentDate",
    "parentView",
    "component",
    "index",
    "hide_options",
    "getAllCompanyTemplatesData",
  ],
  mixins: [CustomDashboardHelper],
  computed: {
    ...mapGetters("entities", ["getEntityDataById"]),
    ...mapGetters("templatesData", [
      "getNewEntityData",
      "getPrimaryEntityDataStatus",
      "getTemplatesData",
      "getTemplatesPrimaryData",
      "getformtemplateinvoiceinfo",
      "getDuplicateDataCheck",
    ]),
    ...mapGetters("auth", ["getDefaultDateFormat"]),
    schedulerWeekDays() {
      const startDate = new Date(
        this.currentWeek.getFullYear(),
        this.currentWeek.getMonth(),
        this.currentWeek.getDate() - this.currentWeek.getDay()
      );
      const weekDays = [];

      for (let i = 0; i < 7; i++) {
        const date = new Date(
          startDate.getFullYear(),
          startDate.getMonth(),
          startDate.getDate() + i
        );
        weekDays.push({
          day: date.getDate(),
          month: date.getMonth() + 1,
          weekday: this.daysOfWeek[date.getDay()],
          tasks: [],
        });
      }

      return weekDays;
    },
    getAllViews() {
      let result = [];
      if (
        this.resource_entity_field?.entity_id &&
        this.allEntities?.[this.resource_entity_field.entity_id]
      ) {
        result.push(this.getView(this.resource_entity_field));
      }
      if (
        this.job_entity_field?.entity_id &&
        this.allEntities?.[this.job_entity_field.entity_id]
      ) {
        result.push(this.getView(this.job_entity_field));
      }
      if (
        this.scheduler_entity_sub_entity_field &&
        this.component?.scheduler_entity_sub_groups
      ) {
        (this.component.scheduler_entity_sub_groups || []).map((e) => {
          let field = this.scheduler_entity_sub_entity_field[e];
          if (field?.entity_id && this.allEntities?.[field.entity_id]) {
            let dividingFilters = (
              this.component?.scheduler_entity_sub_groups_filters || []
            ).filter((fl) => fl.split("@")[0] == e);
            result.push(this.getView(field, dividingFilters));
          }
        });
      }
      return result;
    },
    jobView() {
      return (
        this.allViews.find(
          (e) => e.entity_id == this.job_entity_field?.entity_id
        )?.key || ""
      );
    },
  },
  mounted() {
    this.setEntityIds();
    this.getNeccessaryDetails();
  },
  methods: {
    formatTime(totalHours) {
      if (isNaN(totalHours)) return "00:00";

      const hours = Math.floor(totalHours);

      const minutes = Math.floor((totalHours - hours) * 60);

      const formattedHours = String(hours).padStart(2, "0");
      const formattedMinutes = String(minutes).padStart(2, "0");

      return `${formattedHours}:${formattedMinutes}`;
    },
    getCountValue(day, type) {
      if (type == "JOB") {
        return day?.tasks?.length || 0;
      } else if (type == "RESOURCE") {
        let eCount = 0,
          emp = {};
        (day.tasks || []).forEach((t) => {
          if (
            !emp[
              t[
                this.resource_entity_field.template_id +
                  "#" +
                  this.resource_entity_field.key
              ]
            ]
          ) {
            eCount++;
            emp[
              t[
                this.resource_entity_field.template_id +
                  "#" +
                  this.resource_entity_field.key
              ]
            ] = true;
          }
        });
        return eCount;
      } else if (type == "HOURS") {
        let hCount = 0;
        (day.tasks || []).forEach((t) => {
          if (t && t[this.component.job_entity] && this.job_entity_field?.entity_id && this.component?.job_entity_time_field) {
            let job = (this.allEntitiesData[this.job_entity_field.entity_id] || []).find(
              (e) => e._id == t[this.component.job_entity]
            );
            let [temp, k] = this.component.job_entity_time_field.split("#");
            if(temp && k && job && job.entityData?.[temp]?.[k]){
              hCount += job.entityData[temp][k];
            }
          }
        });
        return hCount;
      }
      return 0;
    },
    calculateTotalHours(dataIds, entity_id, field) {
      let tasks = (this.allEntitiesData?.[entity_id] || []).filter((e) =>
        dataIds.includes(e._id)
      );
      let [t, k] = field.split("#");
      return tasks.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue?.entityData?.[t]?.[k] || 0),
        0
      );
    },
    checkPreviousTasks(obj) {
      let year = new Date(this.currentWeek).getFullYear();
      let { day, month } = obj;
      let slotData = new Date(year, month - 1, day);
      if (
        slotData.getTime() <= new Date().getTime() &&
        this.component?.settings?.allow_previous_slots_replace
      ) {
        return true;
      }
      return false;
    },
    openDialog() {
      if (this.component?.settings) {
        this.updateComponentSettings = { ...this.component.settings };
      }
      this.dialogTitle = "Settings";
      this.dialogVisible = true;
    },
    closeDialog() {
      this.updateComponentSettings = {
        allow_previous_slots_replace: true,
        allow_extra_time_job: false,
      };
      this.dialogTitle = "";
      this.dialogVisible = false;
    },
    saveSettings() {
      this.component["settings"] = { ...this.updateComponentSettings };
      this.dialogTitle = "";
      this.dialogVisible = false;
    },
    async saveChanges() {
      let params = this.buildSlotsData(
        this.allEntitiesData[this.component.scheduler_entity]
      );
      if (params.length) {
        this.saveLoading = true;
        await postAPICall("POST", "/templates-data/bulk-update", params);
        this.$message({
          message:
            (this.allEntities?.[this.scheduler_entity]?.name || "Slot") +
            "updated successfully",
          type: "success",
        });
        this.saveLoading = false;
      } else {
        this.$message({
          message:
            "You haven't changed any " +
            (this.allEntities?.[this.scheduler_entity]?.name || " slot"),
          type: "warning",
        });
      }
    },
    buildSlotsData(data) {
      let result = [];
      let mainTemplates = [
        this.component.scheduler_entity_date_field.split("#")[0],
        ...this.allViews.flatMap((e) => e.key.split("#")[0]),
      ];
      data.forEach((dt) => {
        if (dt.changed) {
          if (dt?.entityData) {
            for (const [key, value] of Object.entries(dt.entityData)) {
              if (mainTemplates.includes(key)) {
                result.push({
                  entity_data_id: dt._id,
                  template_id: key,
                  template_data: value,
                  update_status: true,
                  update_data: true,
                });
              }
            }
          }
        }
      });
      result = [
        ...result,
        ...this.newlyAddedData.map((e) => {
          return {
            entity_data_id: e,
            update_status: true,
            update_data: false,
          };
        }),
      ];
      return result;
    },
    getLastweekDates() {
      let currentDate = new Date(this.currentWeek);
      let startOfWeek = new Date(
        currentDate.setDate(currentDate.getDate() - currentDate.getDay())
      );
      let lastWeekStartDate = new Date(startOfWeek);
      lastWeekStartDate.setDate(lastWeekStartDate.getDate() - 7);

      let lastWeekEndDate = new Date(lastWeekStartDate);
      lastWeekEndDate.setDate(lastWeekEndDate.getDate() + 6);
      return [lastWeekStartDate, lastWeekEndDate];
    },
    getBetweenDatesSlots(data, start, end) {
      let [dateTemplate, dateField] =
        this.component.scheduler_entity_date_field.split("#");
      return data.filter((dt) => {
        if (dt?.entityData?.[dateTemplate]?.[dateField]) {
          let slotDate = new Date(dt?.entityData?.[dateTemplate]?.[dateField]);
          return slotDate >= new Date(start) && slotDate <= new Date(end);
        }
        return false;
      });
    },
    getNextWeekDate(dateStr) {
      let date = new Date(dateStr);
      date.setDate(date.getDate() + 7);
      return date.toISOString();
    },
    buildCopyScedules(data) {
      let [dateTemplate, dateField] =
        this.component.scheduler_entity_date_field.split("#");
      return data.map((d) => {
        let dt = JSON.parse(JSON.stringify(d));
        let existed_data = dt?.entityData;
        if (existed_data?.[dateTemplate]?.[dateField]) {
          existed_data[dateTemplate][dateField] = this.getNextWeekDate(
            existed_data[dateTemplate][dateField]
          );
        }
        return {
          existed_data_id: dt._id,
          data: existed_data,
          hard_duplicate: true,
          status: "ARCHIVED",
          is_created_from_scheduling: true,
        };
      });
    },
    async copyFromLastWeek() {
      let [lastWeekStartDate, lastWeekEndDate] = this.getLastweekDates();
      let slots = this.getBetweenDatesSlots(
        this.allEntitiesData[this.component.scheduler_entity],
        lastWeekStartDate,
        lastWeekEndDate
      );
      if (slots.length) {
        let duplicate_data = this.buildCopyScedules(slots);
        let params = {
          entity_id: this.component.scheduler_entity,
          duplicate_data: duplicate_data,
          current_date: new Date().toISOString(),
        };
        this.copyLoading = true;
        await postAPICall("POST", "/entities-data/duplicate/multiple", params);
        this.$message({
          message:
            (this.allEntities?.[this.scheduler_entity]?.name || "Slot") +
            "copied from last week successfully",
          type: "success",
        });
        if (this.job_entity_field?.entity_id) {
          await this.resetData();
        }
        this.copyLoading = false;
      } else {
        this.$message({
          message:
            "There is no " +
            (this.allEntities?.[this.scheduler_entity]?.name || " slot") +
            " have saved on last week",
          type: "warning",
        });
      }
      console.log(slots);
    },
    selectedView(viewBy) {
      if (viewBy) {
        return this.allViews.find((f) => f.key == viewBy);
      }
      return "";
    },
    generateRandomLightColor() {
      const generateRandomComponent = () => {
        return Math.floor(Math.random() * 102) + 153;
      };

      const r = generateRandomComponent().toString(16).padStart(2, "0");
      const g = generateRandomComponent().toString(16).padStart(2, "0");
      const b = generateRandomComponent().toString(16).padStart(2, "0");

      return `#${r}${g}${b}`;
    },
    onEnd(event, presentId) {
      if (event?.to?.offsetParent?.id) {
        let selectedId = event?.item?.id || "";
        let [month, date, exId, exlabel] = event.to.offsetParent.id.split("_");
        let [pMonth, pDate] = presentId.split("_");
        if (
          presentId !== event.to.offsetParent.id &&
          this.scheduleEntityDataObject?.[pDate + "-" + pMonth]?.[selectedId]
        ) {
          let selectedItem =
            this.scheduleEntityDataObject[pDate + "-" + pMonth][selectedId];

          if (selectedItem) {
            selectedItem["day"] = date;
            selectedItem[this.viewBy] = exId;
            selectedItem["month"] = month;
          }
          // let newIndex = this.scheduleEntityData.findIndex(
          //   (e) => e.day == date && e.month == month
          // );
          let existedIndex = this.scheduleEntityData.findIndex(
            (e) => e.day == pDate && e.month == pMonth
          );
          if (this.scheduleEntityData[existedIndex]?.tasks) {
            this.scheduleEntityData[existedIndex]?.tasks.filter((e) => {
              e._id !== selectedItem._id;
            });
          }
          // if (this.scheduleEntityData[newIndex]?.tasks) {
          //   let duplicate = this.scheduleEntityData[newIndex].tasks.find(
          //     (e) => e._id == selectedItem._id
          //   );
          //   if (!duplicate) {
          //     this.scheduleEntityData[newIndex].tasks.push(selectedItem);
          //   }
          // } else {
          //   this.scheduleEntityData[newIndex]["tasks"] = [selectedItem];
          // }
          let rowObject = JSON.parse(
            JSON.stringify(this.scheduleEntityDataObject[pDate + "-" + pMonth])
          );
          delete rowObject[selectedId];
          this.$set(
            this.scheduleEntityDataObject,
            pDate + "-" + pMonth,
            rowObject
          );
          this.scheduleEntityDataObject[date + "-" + month] = {
            ...this.scheduleEntityDataObject[date + "-" + month],
            ...{
              ...{
                [selectedId]: selectedItem,
              },
            },
          };
          this.allEntitiesData[this.component.scheduler_entity] =
            this.allEntitiesData[this.component.scheduler_entity].map((el) => {
              if (el?._id === selectedItem._id) {
                el.changed = true;

                let [dateTemplate, dateField] =
                  this.component.scheduler_entity_date_field.split("#");
                if (el?.entityData?.[dateTemplate]?.[dateField]) {
                  el.entityData[dateTemplate][dateField] = moment(
                    new Date(el.entityData[dateTemplate][dateField])
                  )
                    .month(month - 1)
                    .date(date)
                    .toISOString();
                }

                let [jobTemplate, jobField] = this.viewBy.split("#");
                if (
                  jobTemplate &&
                  jobField &&
                  el?.entityData?.[dateTemplate]?.[jobField]
                ) {
                  el.entityData[jobTemplate][jobField] = exId;
                  el.entityData[jobTemplate][jobField + "/name"] = exlabel;
                }
              }
              return el;
            });
        }
      }
    },
    prepareScheduleEntityData() {
      this.scheduleEntityData = [];
      let weeks = [...this.schedulerWeekDays];
      let [dateTemplate, dateField] =
        this.component.scheduler_entity_date_field.split("#");
      if (this.allEntitiesData[this.component.scheduler_entity]) {
        (this.allEntitiesData[this.component.scheduler_entity] || []).forEach(
          (data, i) => {
            if (data?.entityData?.[dateTemplate]?.[dateField]) {
              let [day, month] = moment(
                new Date(data.entityData[dateTemplate][dateField])
              )
                .format("D-M")
                .split("-");
              let obj = {
                _id: data._id,
                label: this.getLabel(this.allEntities[data.entity_id], data, i),
                day: day,
                month: month,
              };
              (this.allViews || []).forEach((view) => {
                obj[view.key] = this.getViewByData(
                  data,
                  this.selectedView(view.key)
                );
              });
              let dayIndex = weeks.findIndex(
                (wk) => wk.day == day && wk.month == month
              );
              if (dayIndex !== -1) {
                if (weeks[dayIndex].tasks) {
                  let duplicate = weeks[dayIndex].tasks.find(
                    (e) => e._id == data._id
                  );
                  if (!duplicate) {
                    weeks[dayIndex].tasks.push(obj);
                  }
                } else {
                  weeks[dayIndex].tasks = [obj];
                }
              }
              this.scheduleEntityDataObject[day + "-" + month] = {
                ...this.scheduleEntityDataObject[day + "-" + month],
                ...{
                  [data._id]: obj,
                },
              };
            }
          }
        );
      }
      // console.log("this.allEntitiesData", this.allEntitiesData);
      this.scheduleEntityData = [...weeks];
    },
    getViewByData(data, selectedView) {
      if (selectedView?.key) {
        let [viewByTemplate, viewByField] = selectedView.key.split("#");
        return data?.entityData?.[viewByTemplate]?.[viewByField] || "";
      }
      return null;
    },
    async resetData() {
      await this.fetchEntityData(this.component.scheduler_entity);
      this.allViews = this.getAllViews;
      this.setColours();
      this.prepareScheduleEntityData();
    },
    async newDataCreated(data) {
      if (data?.dataId) {
        this.newlyAddedData.push(data.dataId);
      }
      this.resetData();
      this.addingNewData = false;
    },
    setEntityIds() {
      if (this.component?.resource_entity) {
        let [template_id, field_key] =
          this.component.resource_entity.split("#");
        let selectedTemplate = this.getAllCompanyTemplatesData.find(
          (e) => e._id == template_id
        );
        this.resource_entity_field = (
          selectedTemplate.sections?.[0]?.fields || []
        ).find((e) => e.key == field_key);
        this.resource_entity_field["template_id"] = template_id;
      }
      if (this.component?.job_entity) {
        let [template_id, field_key] = this.component.job_entity.split("#");
        let selectedTemplate = this.getAllCompanyTemplatesData.find(
          (e) => e._id == template_id
        );
        this.job_entity_field = (
          selectedTemplate.sections?.[0]?.fields || []
        ).find((e) => e.key == field_key);
        this.job_entity_field["template_id"] = template_id;
      }
      if (this.component?.scheduler_entity_sub_groups) {
        (this.component.scheduler_entity_sub_groups || []).map((e) => {
          let [template_id, field_key] = e.split("#");
          let selectedTemplate = this.getAllCompanyTemplatesData.find(
            (el) => el?._id == template_id
          );
          let field = (selectedTemplate.sections?.[0]?.fields || []).find(
            (e) => e.key == field_key
          );
          field["template_id"] = template_id;

          this.$set(this.scheduler_entity_sub_entity_field, e, field);
        });
      }
    },
    openSettings() {},
    deleteComponent() {
      this.$confirm("Are you sure to delete the scheduler?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$emit("deleteComponent", this.index);
      });
    },
    openDataModal(row, day, tasks = []) {
      if (tasks.length && this.viewBy !== this.component.job_entit) {
        const tasksHours = this.calculateTotalHours(
          tasks
            .filter((e) => e[this.component.job_entity])
            .flatMap((e) => e[this.component.job_entity]),
          this.job_entity_field.entity_id,
          this.component.job_entity_time_field
        );
        const [t, k] = this.component.resource_entity_time_field.split("#");

        const employeeHours = row?.entityData?.[t]?.[k] || 0;

        if (employeeHours <= tasksHours) {
          if (
            !this.component?.settings ||
            !this.component?.settings?.allow_extra_time_job
          ) {
            this.$message({
              message: "Warning, Employee have enough tasks for this day",
              type: "warning",
            });
            return;
          } else if (this.component?.settings?.allow_extra_time_job) {
            this.$confirm(
              "Are you sure to add extra tasks for this employee",
              "Warning",
              {
                confirmButtonText: "OK",
                cancelButtonText: "Cancel",
                type: "warning",
              }
            ).then(() => {
              this.createNewSlot(row, day);
            });
            return;
          }
        }
      }
      this.createNewSlot(row, day);
    },
    createNewSlot(row, day) {
      this.schedulingTemplateForm = {};
      this.prepareSchedulingEntityTemplate(row, day);
      this.addingNewData = true;
    },
    getView(field, filters = null) {
      let { name, _id } = this.allEntities[field.entity_id];
      return {
        name: `View by ${name}`,
        entity_id: _id,
        key: field.template_id + "#" + field.key,
        filters,
      };
    },
    prepareSchedulingEntityTemplate(row, day) {
      this.currentDate = moment(
        new Date(
          this.currentWeek.getFullYear(),
          this.currentWeek.getMonth(),
          day
        )
      ).format(this.getDefaultDateFormat);
      if (
        this.component?.scheduler_entity &&
        this.allEntities?.[this.component.scheduler_entity]?.templates?.[0]
          ?.templateInfo
      ) {
        let schedulingTemplate =
          this.allEntities?.[this.component.scheduler_entity].templates[0]
            .templateInfo;
        let fields = schedulingTemplate?.sections?.[0]?.fields || [];
        fields.forEach((fd) => {
          if (
            ["DATE", "DATE_TIME"].includes(fd?.inputType) &&
            this.component.scheduler_entity_date_field ==
              schedulingTemplate._id + "#" + fd.key
          ) {
            this.schedulingTemplateForm[fd.key] = new Date(
              this.currentWeek.getFullYear(),
              this.currentWeek.getMonth(),
              day
            );
          } else if (fd.input_type == "ENTITY") {
            if (this.viewBy == schedulingTemplate._id + "#" + fd.key) {
              this.schedulingTemplateForm[fd.key] = row._id;
              this.schedulingTemplateForm[fd.key + "/name"] = this.getLabel(
                this.allEntities[
                  this.selectedView(this.viewBy)?.entity_id || ""
                ],
                row,
                1
              );
            }
          }
        });
      }
    },
    getLabel(selectedEntity, item, i) {
      let name =
        item &&
        item.entity_prime_data &&
        Object.keys(item.entity_prime_data) &&
        item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          ? item.entity_prime_data[Object.keys(item.entity_prime_data)[0]]
          : "data " + i;
      if (selectedEntity && selectedEntity.primaryFields) {
        let primaryField = selectedEntity.primaryFields[0];
        let key =
          primaryField && primaryField["key"] ? primaryField["key"] : null;
        if (key) {
          if (
            item.entity_prime_data &&
            item.entity_prime_data[key] &&
            item.entity_prime_data[key + "/name"]
          ) {
            name = item.entity_prime_data[key + "/name"]
              ? item.entity_prime_data[key + "/name"]
              : item.entity_prime_data[key];
          } else {
            name =
              item.entityData &&
              primaryField.template_id &&
              item.entityData[primaryField.template_id]
                ? item.entityData[primaryField.template_id][key + "/name"]
                  ? item.entityData[primaryField.template_id][key + "/name"]
                  : item.entityData[primaryField.template_id][key]
                  ? item.entityData[primaryField.template_id][key]
                  : "data" + i
                : "data" + i;
          }
        }
      }
      return name;
    },
    async getNeccessaryDetails() {
      let promises = [];
      if (this.resource_entity_field?.entity_id) {
        promises.push(
          this.fetchEntityData(
            this.resource_entity_field.entity_id,
            this.resource_entity_field.filters || [],
            this.resource_entity_field.key
          )
        );
        this.viewBy =
          this.resource_entity_field.template_id +
          "#" +
          this.resource_entity_field.key;
      }
      if (this.job_entity_field?.entity_id) {
        promises.push(
          this.fetchEntityData(
            this.job_entity_field.entity_id,
            this.job_entity_field.filters || [],
            this.job_entity_field.key
          )
        );
      }
      if (this.component?.scheduler_entity) {
        promises.push(this.fetchEntityData(this.component.scheduler_entity));
      }
      if (
        this.scheduler_entity_sub_entity_field &&
        this.component?.scheduler_entity_sub_groups
      ) {
        (this.component.scheduler_entity_sub_groups || []).map((e) => {
          let field = this.scheduler_entity_sub_entity_field[e];
          let dividingFilters = (
            this.component?.scheduler_entity_sub_groups_filters || []
          ).filter((fl) => fl.split("@")[0] == e);
          if (dividingFilters.length) {
            dividingFilters.map((fl) => {
              promises.push(
                this.fetchEntityData(
                  field.entity_id,
                  [],
                  field.key,
                  fl.split("@")[1],
                  true
                )
              );
              promises.push(this.filtersData(fl.split("@")[1]));
            });
          } else {
            promises.push(
              this.fetchEntityData(
                field.entity_id,
                field.filters || [],
                field.key
              )
            );
          }
        });
      }
      await Promise.all(promises);
      this.allViews = this.getAllViews;
      this.setColours();
      this.prepareScheduleEntityData();
    },
    setColours() {
      if (
        this.job_entity_field?.entity_id &&
        this.allEntitiesData[this.job_entity_field?.entity_id]
      ) {
        this.allEntitiesData[this.job_entity_field.entity_id].forEach((job) => {
          this.jobColors[job._id] = this.generateRandomLightColor();
        });
      }
    },
    async filtersData(filterId) {
      if (!this.allFiltersData[filterId]) {
        this.allFiltersData[filterId] = await fetchFilterById(filterId);
      }
    },
    async fetchEntityData(
      entity_id,
      filters = [],
      key = "",
      filter_id = "",
      dividingFilteredData = false
    ) {
      let params = {
        entity_id: entity_id,
        limit: 500,
        page: 1,
        filters: this.mapDateFilters(
          filters.filter(
            (e) =>
              e.data_source !== "self_field" && e.data_source !== "TEMPLATE"
          )
        ),
        filter_id: filter_id,
      };
      if (entity_id == this.component.scheduler_entity) {
        params["include_scheduling_data"] = true;
      }
      let entityDataResponse = await postAPICall(
        "POST",
        "/entities-data/entity/data",
        params
      );
      let entityData = entityDataResponse.data.map((data, i) => {
        data.label = this.getLabel(entityDataResponse.selectedEntity, data, i);
        return data;
      });
      this.allEntities[entity_id] = entityDataResponse.selectedEntity;
      if (dividingFilteredData) {
        this.allDividedEntitiesData[filter_id] = entityData;
      } else {
        this.allEntitiesData[entity_id] = entityData;
        let dependencyFilter = filters.find(
          (e) => e.data_source == "self_field" || e.data_source == "TEMPLATE"
        );
        if (key && !dependencyFilter) {
          this.entityFieldsPreferredData[key] = {
            ...entityDataResponse,
            ...{
              update: true,
            },
          };
        }
      }
    },
    changeWeek(reset = false, add = false) {
      if (reset) {
        this.currentWeek = new Date();
      } else {
        this.currentWeek = new Date(
          this.currentWeek.getFullYear(),
          this.currentWeek.getMonth(),
          add ? this.currentWeek.getDate() + 7 : this.currentWeek.getDate() - 7
        );
      }
      if (this.currentYear != this.currentWeek.getFullYear()) {
        this.currentYear = this.currentWeek.getFullYear();
        this.$emit("newEntityData", {
          date: this.currentWeek,
          view: this.currentView,
        });
      }
      this.prepareScheduleEntityData();
    },
    changeView(view) {
      this.currentView = view;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-dashboard-scheduler {
  border: 1px solid #eaeaea;
  border-radius: 5px;
  margin-bottom: 0px;
  margin-top: 0px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1) !important;
  background-color: #ffffff;
}
.left-card {
  flex: 1;
  width: 100%;
}
.right-card {
  flex: 4;
  width: 100%;
  justify-content: end;
  align-items: center;
}
</style>

<style scoped lang="scss">
.weekday-container {
}
.weekday-single {
  width: 100% !important;
  height: 14px;
  border-radius: 4px;
  font-size: 9px;
  color: white;
  margin-top: 2px;
}
.event-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 3px;
  min-height: 6px;
}
.single-event {
  height: 6px;
  width: 6px;
  border-radius: 50%;
}
.calendar {
  border-top: 1px solid #ccc;
  /* padding: 10px; */
  width: 100%;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.child-a {
  display: flex;
  align-items: center;
  gap: 10px;
}

.today-icon-container {
  display: inline-block;
  position: relative;
}

.prev-next-btn {
  font-size: 20px;
  color: #007bff;
  margin: 0 10px;
}

.current-date {
  font-size: 16px;
  color: #333;
}

.view-select {
  width: 100px;
  font-size: 14px;
  color: #333;
  margin-right: 20px;
}

.el-tooltip__popper {
  font-size: 12px; /* Tooltip font size */
}

/* Styles for the calendar table */
table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  text-align: center;
  border: 1px solid #ccc;
}
td {
  height: 75px;
  position: relative;
  vertical-align: center;
}

td .cell-content {
  padding: 10px;
}

.current-day {
  background-color: lightblue;
}

.cell-content {
  position: relative;
  padding: 5px;
}

.event {
  background-color: #f0f0f0;
  border-radius: 3px;
  padding: 2px 5px;
  margin-top: 2px;
}

.event:first-child {
  margin-top: 5px;
}
.table-header {
  font-weight: bold !important;
}

.table-cell {
  position: relative;
  padding: 5;
}

.hidden-button {
  display: none;
}

.table-cell:hover .hidden-button {
  display: block;
}

.job-card {
  //background-color: lightblue !important;
  width: 100% !important;
  border-radius: 5px;
  cursor: pointer;
}
</style>
